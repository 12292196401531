@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.bar {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  padding: 30px;
  border-left: solid var(--bs-border-width) var(--bs-border-color);
  background: white;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.navbarTogglerIcon {
  padding: 10px;
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.listGroupItem {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;  
  width: 100%;
  .listGroupItemIcon {
    padding-right: 10px;
  }
  .listGroupItemDescription {
    padding: 0 20px 20px;
    border-left: dotted var(--bs-border-width) var(--bs-border-color);
    margin-left: 10px;    
  }
  .noBorder {
    border-left: none;
  }
}



