@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";


.offcanvasBottom {
  height: 30vh !important;
  @media (min-width: 739px) {
    height: 170px !important;
  }
  
  .offcanvasBottomHeader {
    justify-content: flex-end;
  }
  .offcanvasBottomBody{
    text-align: center;
    h5 {
      padding: 0.25rem;
    }
  }
  .buttonContainer{
    display: flex;
    justify-content: center;
    align-content: center;
  }
}