html,
body,
#root {
  padding: 0;
  width: 100%;
  height: 100%;
}

.main-wrapper {
  width: 100%;
  height: 100%;
  background: #f3f4f6;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "sidebar header"
    "sidebar main";
}

.main-header {
  grid-area: header;
  padding: 0.5rem;
}

.main-container {
  grid-area: main;
  box-shadow: inset 2px 1px 9px 1px rgba($dark, 0.10);
  overflow: auto;
  padding-top: 1rem;
}

.sidebar-menu {
  padding-top: 0;
  flex-direction: column;
  grid-area: sidebar;
  overflow-y: auto;
}

.breadcrumb {
  --bs-breadcrumb-margin-bottom: 3rem;
}

.modal {
  --bs-modal-border-radius: 0;
}

.btn,.form-control, .form-select  {
  border-radius: 0;
}

.form-label {
  font-weight: bold;
}

.modal-title { 
  text-align: center;
  flex: 1;
}

:root {
  --max-width: 150px;
  --max-height: 150px;


  @include respond-below(lg) {
    --max-width: 100px;
    --max-height: 100px;
  }

  @include respond-below(md) {
    --max-width: 50px;
    --max-height: 50px;

  }
}

