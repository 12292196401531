@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

.content {
  display: flex;
  height: 100%;
  padding-top: 20px;
  padding-right: 30px;
  flex-direction: column;
  background: #F5F5F5;
}

.frame {
  display: flex;
  justify-content: center;
  flex:1;
  height: 85vh;
}

.pdfContent {
    display: flex;
    justify-content: center;
}

.tabContainer {
    //  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
     background: #F5F5F5;
}