@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

 .buttonsContainer {
        margin: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex: 1.4;

        @include respond-below(lg) {
            flex: 1.2;
            margin-left: 0.5rem;
            margin-top: 2rem;
            width: 100%;
        }

        .button {
            margin-left: 0.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;           

            @include respond-below(lg) {                
                width: 100%;
                align-items: center;
                justify-content: center;
                margin-left: 0.5rem;
            }
        }
    }

    .viewerIcon {
      padding: 0 10px;
      cursor: pointer;
    }

    .listGroupItem {
        position: relative;
        display: block;
        padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
        color: var(--bs-list-group-color);
        text-decoration: none;  
        width: 100%;
        .listGroupItemIcon {
          padding-right: 10px;
        }
        .listGroupItemDescription {
          padding: 0 20px 20px;
          border-left: dotted var(--bs-border-width) var(--bs-border-color);
          margin-left: 10px;    
        }
        .noBorder {
          border-left: none;
        }
      }