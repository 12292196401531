@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.filesList {
    display: flex;
}

.fileButton {
    padding: 0.7rem 1rem;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: 0;
    padding-right: 0.5rem;
    margin-bottom: 0.5rem;

    label {
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        word-break: keep-all;
        text-overflow: ellipsis;
        margin-bottom: 0px;
    }
}


.fileButton {
    justify-content: space-between;
    border: dashed #E8E8E8;
    // min-width: 6rem;
    
    .removeIcon {
        min-width: 1rem;
        margin-left: 0.5rem;
    }
}

.withoutFile {
    margin: 0.5rem;
    font-size: smaller;
}

.containerSmallButton{
    display: inline-flex;
    align-items: center;
    
    .icon{
        width: 1rem;
    }
}

.inline{
    display: flex;
    flex-direction: row;
}

.inLineButton{    
    word-break: keep-all;
    margin-bottom: 0px;
    margin-right: 0.7rem;
}