@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/variables.scss";

.bg_color {
    background-color: var(--bs-white);
}

.NavBar {
    background-color: var(--bs-white);
}

.logo {
    width: 100px;
}

.mr-3 {
    margin-right: 3rem;
}